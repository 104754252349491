.footer {
  text-align: center;
  padding: 2rem;
  background-color: #007bff;
  color: white;
  margin-top: 2rem;
}

.footer h2 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 2.5rem; /* Reduce gap between icons */
  margin-bottom: 2rem;
  flex-wrap: wrap; /* Allow icons to wrap to new line if needed */
}

.social-icon {
  font-size: 40px; /* Reduce icon size */
  color: white;
}

.legal-links {
  display: flex;
  justify-content: center;
  gap: 1rem; /* Adjust gap between links */
}

.legal-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

.legal-links a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer {
    padding: 1.5rem;
  }

  .footer h2 {
    font-size: 1.25rem;
  }

  .social-icons {
    gap: 1rem; /* Adjust gap between icons */
  }

  .social-icon {
    font-size: 25px; /* Adjust icon size for mobile */
  }

  .legal-links {
    flex-direction: row; /* Ensure links are on the same line */
    gap: 1rem;
  }

  .legal-links a {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 1rem;
  }

  .footer h2 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .social-icons {
    gap: 2rem; /* Adjust gap between icons */
    margin-bottom: 1.5rem;
  }

  .social-icon {
    font-size: 25px; /* Adjust icon size for mobile */
  }

  .legal-links {
    flex-direction: row; /* Ensure links are on the same line */
    gap: 2rem; /* Adjust gap between the links */
  }

  .legal-links a {
    font-size: 0.8rem;
  }
}
