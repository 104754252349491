@import url('https://fonts.googleapis.com/css2?family=Montagu+Slab:opsz,wght@16..144,100..700&family=Montserrat:ital,wght@0,100..900&display=swap');

body {
  font-family: 'Montserrat', sans-serif; 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #007bff;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.header h1 {
  font-family: 'Montserrat', sans-serif; 
  font-weight: normal;
  margin: 0;
  color: white;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 50px; 
  width: auto;
  margin-right: 0.5rem;
}

.button {
  font-family: 'Montserrat', sans-serif; 
  font-weight: 600;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.button:hover {
  background-color: #0056b3;
}
