@import url('https://fonts.googleapis.com/css2?family=Montagu+Slab:opsz,wght@16..144,100..700&family=Montserrat:ital,wght@0,100..900&display=swap');

body {
  font-family: 'Montserrat', sans-serif; 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.home {
  text-align: center;
  padding-top: 4rem; /* Reduce padding to remove the white bar */
}

.hero {
  background: #007bff;
  color: white;
  padding: 5rem 0 5rem 0;
  border-radius: 0 0 50% 50%;
  overflow: hidden;
  position: relative;
}

.hero::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3rem; /* Adjust height of the fade */
  background: linear-gradient(to bottom, rgba(0, 123, 255, 0) 0%, rgba(0, 123, 255, 1) 100%);
}

.hero h2 {
  font-family: 'Montserrat', sans-serif; 
  font-size: 5rem; /* Increase the font size */
  margin: 0;
}

.download-buttons {
  display: flex;
  flex-direction: column; /* Stack badges in a column */
  justify-content: center;
  align-items: center; /* Center align badges */
  gap: 1rem;
  margin-top: 3rem;
}

.download-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0056b3; /* Lighter shade of blue */
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-size: 1.5rem;
  width: 200px; /* Set a fixed width */
  height: 60px; /* Set a fixed height */
}

.download-icon {
  margin-right: 0.5rem;
  font-size: 2rem;
}

.intro {
  padding: 6rem 0rem 0rem 0;
}

.intro h2 {
  font-family: 'Montserrat', sans-serif; 
  font-size: 3.5rem; /* Adjust font size for mobile */
  margin: 0;
  display: flex;
  flex-direction: column; /* Display lines in a column */
  align-items: center; /* Center align text */
}

.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 4rem; /* Adjust padding */
  background-color: #ffffff;
}

.feature {
  background-color: #e5f4ff;
  padding: 1rem;
  border-radius: 10px;
  text-align: center; /* Center align text */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feature-icon {
  font-size: 3rem;
  margin-bottom: 1rem; /* Add margin below the icon */
}

.feature-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.25rem; /* Adjust font size */
  font-weight: 700;
  margin: 0 0 0.5rem;
}

.feature p {
  font-family: 'Montserrat', sans-serif; 
  font-size: 1rem; 
  margin: 0;
  padding-bottom: 1rem; /* Add padding below the paragraph */
}

.signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
}

.signup-form input[type="email"] {
  padding: 0.75rem;
  font-size: 1.25rem;
  border: none;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  width: 80%;
  max-width: 400px;
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
}

.signup-form button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  font-size: 1.25rem;
  color: white;
  background-color: #0056b3;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 80%;
  max-width: 400px;
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
}

.signup-form button .send-icon {
  margin-left: 1rem;
  font-size: 1rem;
}

.signup-form button:hover {
  background-color: #003e82;
}

.thank-you-message {
  margin-bottom: 1rem;
}

.thank-you {
  color: white;
  font-size: 1.25rem;
  margin: 0; /* Remove margin to have the text appear directly below each other */
}

.temp {
  margin-top: 3rem;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .hero {
    padding: 5rem 0 5rem 0;
    border-radius: 0 0 35% 35%;
  }

  .features {
    grid-template-columns: 1fr;
  }

  .hero h2 {
    font-size: 2.5rem; /* Decrease the font size for h2 on mobile */
  }

  .download-badge {
    width: 60%; /* Adjust width for mobile */
    max-width: 280px; /* Ensure max width on mobile */
    height: auto; /* Adjust height for mobile */
  }

  .download-icon {
    font-size: 1.75rem; /* Adjust icon size for mobile */
  }

  .intro h2 {
    font-size: 2rem; /* Adjust subheading font size for mobile */
  }

  .features {
    padding: 2rem; /* Adjust padding for mobile */
  }

  .signup-form {
    margin-top: 3rem;
  }

  .signup-form input[type="email"],
  .signup-form button {
    font-size: 1rem;
    width: 80%;
    max-width: 280px;
  }

  .signup-form button .send-icon {
    font-size: 1.25rem;
  }

  .thank-you {
    font-size: 0.75rem;
  }

  .temp {
    font-size: 1rem;
  }
}
